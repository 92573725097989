body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden auto;
}

.MuiFormLabel-root.Mui-focused {
  color: #3E8AFF!important;
}

.MuiInput-underline:after {
  border-bottom-color: #03a9fa!important;
}

.MuiButton-containedPrimary {
  background-color: #03a9fa!important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #3E8AFF!important;
}

.MuiSvgIcon-colorPrimary {
  color: #3E8AFF!important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #3E8AFF!important;
}

.MuiInputLabel-outlined {
  top: -6px!important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: 0px!important;
}

.MuiOutlinedInput-input {
  padding: 12px!important;
}

.MuiSelect-select:focus {
  background-color: transparent!important;
}

.MuiFormLabel-root {
  font-weight: 500!important;
  color: rgba(51, 77, 110, 0.5)!important;
  font-size: 13px!important;
  line-height: 16px!important;
}

.MuiFormLabel-root.Mui-focused {
  color: #3E8AFF!important;
}

.MuiFormHelperText-root {
  font-size: 11px!important;
}

input {
  font-size: 13px!important;
  line-height: 20px!important;
}

legend {
  font-size: 0.6rem!important;
}

.MuiStepper-root {
  padding: 0px!important;
}

/* .MuiStep-horizontal {
  padding-right: 0px!important;
  padding-left: 0px!important;
} */

.MuiStepConnector-line {
  /* margin-right: 55px!important; */
  position: absolute;
  left: -1px;
  border: 1px solid red;
  border-color: #DAE2EC!important;
  margin-top: 0px;
  padding-right: 2px;
  width: 10px!important;
}

.MuiGrid-align-items-xs-flex-start .MuiStepConnector-line {
  /* margin-right: 55px!important; */
  position: absolute;
  left: -10px;
  border: 1px solid red;
  border-color: #DAE2EC!important;
  margin-top: 0px;
  padding-right: 2px;
  width: 10px!important;
}

.MuiStepIcon-active {
  fill: #678190!important;
}

.MuiStepIcon-text {
  fill: rgba(51, 77, 110, 0.6)!important;
}

.MuiStepIcon-active .MuiStepIcon-text {
  fill: #fff!important;
}

/* .MuiStepIcon-completed {
  /* fill: #678190!important; */
/* } */

/* .MuiStepIcon-root .MuiStepIcon-completed { */
  /* fill: #fff!important; */
/* } */

/* .MuiStepIcon-root .MuiStepIcon-completed .MuiStepIcon-text { */
  /* fill: rgba(51, 77, 110, 0.6)!important; */
/* } */

/* .MuiStepConnector-completed.MuiStepIcon-text { */
  /* fill: #C2CFE0!important;
  color: rgb(17, 16, 16)!important; */
/* } */

.bannerold:before {
  content: '';
  display: block;
  width: 192px;
  height: 122px;
  background-color: #fff;
  opacity: 0.21;
  border-radius: 40px 40px 40px 0;
  position: absolute;
  left: 330px;
  bottom: 520px;
  z-index: 1;
}

.bannerold:after {
  content: '';
  display: block;
  width: 289px;
  height: 158px;
  background-color: #fff;
  opacity: 0.3;
  border-radius: 40px 40px 0 40px;
  position: absolute;
  left: 14px;
  bottom: 340px;
  z-index: 2;
}

.grecaptcha-badge { 
  bottom: 90px !important; 
}

/*# sourceMappingURL=swagger-ui.css.map*/

svg.arrow {
  width: 16px;
  margin-right: 8px;
}

.banner {
  background-color: #0B1C32 !important;
  background-image: url('/images/signup/sign-up-bg-light.png');
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 30px 50px;
  box-sizing: border-box;
}
.banner::after, .banner::before {
  box-sizing: border-box;
}
.banner__image {
  width: 380px;
  margin-top: 35px;
}
.banner__heading {
  color: #fff;
  margin-bottom: 8px;
  line-height: 27px;
  font-size: 25px;
  width: 100%;
}
.banner__description {
  margin-bottom: 40px;
  color: #fff !important;
  font-size: 18px !important;
}
.banner__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.banner__wrapper-inner {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.banner__list-item {
  display: flex;
  background: rgba(255, 255, 255, 0.06);
  margin-bottom: 10px;
  border-radius: 12px;
  align-items: center;
}
.banner__icon-wrapper {
  margin-right: 0;
}
.banner__paragraph {
  color: color(grey, 3x-light-grey) !important;
  line-height: 20px !important;
  font-size: 16px !important;
  padding: 12px 0;
  margin: 0;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  width: 436px;
  background-color: rgb(14, 39, 86);
}

.hide-scroll {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.hide-scroll::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.show-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 6px;
}

.show-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

/*It doesn't allow scrolling in any direction*/
.no-scroll{
  overflow: hidden;
}
/*Styling scroll appearance in y direction*/
.y-scroll{
  overflow: clip auto;
}
.y-scroll::-webkit-scrollbar{
  width:4px;
}
.y-scroll::-webkit-scrollbar-thumb{
  background-color:#4C4E6499;
  border-radius:10px;
}
.y-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#4C4E645F;
}
.y-scroll::-webkit-scrollbar-track{
  background-color:#fefefe;
}
/*Styling scroll appearance in x direction*/
.x-scroll{
  overflow:auto clip;
}
.x-scroll::-webkit-scrollbar{
  height:4px;
}
.x-scroll::-webkit-scrollbar-thumb{
  background-color:#4C4E6499;
  border-radius:10px;
}
.x-scroll::-webkit-scrollbar-track{
  background-color:#fefefe;
}
.x-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#4C4E645F;
}
/*It allows scrolling in both direction if possible*/
.xy-scroll{
  overflow: auto;
}
.xy-scroll::-webkit-scrollbar{
  height:4px;
  width:4px;
}
.xy-scroll::-webkit-scrollbar-thumb{
  background-color:#4C4E6499;
  border-radius:10px;
}
.xy-scroll::-webkit-scrollbar-track{
  background-color:#fefefe;
}
.xy-scroll::-webkit-scrollbar-thumb:hover{
  background-color:#4C4E645F;
}

